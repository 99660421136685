/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/docsify@4.11.6/lib/docsify.min.js
 * - /npm/docsify-copy-code@3.0.0/dist/docsify-copy-code.min.js
 * - /npm/docsify-themeable@0.8.6/dist/js/docsify-themeable.min.js
 * - /npm/docsify-pagination@2.6.2/dist/docsify-pagination.min.js
 * - /npm/prismjs@1.20.0/components/prism-bash.min.js
 * - /npm/prismjs@1.20.0/components/prism-markdown.min.js
 * - /npm/prismjs@1.20.0/components/prism-scss.min.js
 * - /npm/prismjs@1.20.0/components/prism-json.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
